import ExcelJS from "exceljs";
import { saveAs } from 'file-saver';
import DrawExcel from "@/commonFuncion/DrawExcel";

export async function exportToExcelStatistics(exportToExcelData, sheetDate, listExportExcelStaticsBySheetDate, typeSheet) {

    const fileName = exportToExcelData.nameFile;
    const workbook = new ExcelJS.Workbook();
    const dataListTable = sheetDate

    for (let i = 0; i < dataListTable.length; i++) {
        const sheetName = dataListTable[i];
        await addDataList(workbook, sheetName,listExportExcelStaticsBySheetDate,typeSheet);
    }
    // Dữ liệu truyền vào từ component
    async function addDataList(workbook, sheetName) {
        const titleFile = exportToExcelData.titleFile;
        let columnHeaders = []
        if (typeSheet === 'date'){
            columnHeaders = [
                'STT', 'Tên lớp', 'Sĩ số', 'Ăn sáng',
                'Phụ sáng', 'Ăn trưa','Ăn chiều', 'Phụ chiều', 'Ăn tối', 'Ghi chú'
            ]
        }else {
            columnHeaders = [
                'STT', 'Ngày', 'Sĩ số', 'Ăn sáng',
                'Phụ sáng', 'Ăn trưa','Ăn chiều', 'Phụ chiều', 'Ăn tối', 'Ghi chú'
            ]
        }
        // const columnHeaders = exportToExcelData.columnHeaders;
        const companyInfo = exportToExcelData.companyInfo;
        const columnWidths = exportToExcelData.columnWidths;
        let dataList = []
        dataList = await  getDataTable(sheetName,listExportExcelStaticsBySheetDate,typeSheet)
        // const fileName = exportToExcelData.nameFile;
        //
        // const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(sheetName);

        await DrawExcel.drawColumnWidths(columnWidths, worksheet);

        await DrawExcel.drawCompanyInfo(companyInfo, worksheet);
        await DrawExcel.drawTitleRow(titleFile, worksheet);
        worksheet.addRow([]);
        await DrawExcel.drawTableHeadersStatistics(columnHeaders, worksheet);
        await DrawExcel.drawTableStatistics(dataList, worksheet);
        let otherObject = []
        otherObject = await calculateTotalMoney(sheetName,listExportExcelStaticsBySheetDate,typeSheet)
        await DrawExcel.drawTotalDataStatistics(otherObject, worksheet);
    }
    async function getDataTable(sheetName,listExportExcelStaticsBySheetDate,typeSheet){
        let dataList = []
        if (typeSheet === 'date'){
            for (let i = 0; i < listExportExcelStaticsBySheetDate.length; i++) {
                if (sheetName === listExportExcelStaticsBySheetDate[i].nameSheet){
                    dataList.push(listExportExcelStaticsBySheetDate[i])
                }
            }
            return dataList.map((x, index) => [
                index + 1,
                x.className,
                x.numberOfStudents,
                x.numberHaveBreakfast,
                x.numberEatBreakfastSnacks,
                x.numberHavingLunch,
                x.numberHavingAfternoon,
                x.numberEatAfternoonSnack,
                x.numberHaveDinner,
                '',
            ]);
        }else {
            for (let i = 0; i < listExportExcelStaticsBySheetDate.length; i++) {
                if (sheetName === listExportExcelStaticsBySheetDate[i].className){
                    dataList.push(listExportExcelStaticsBySheetDate[i])
                }
            }
            return dataList.map((x, index) => [
                index + 1,
                x.dateAttendance,
                x.numberOfStudents,
                x.numberHaveBreakfast,
                x.numberEatBreakfastSnacks,
                x.numberHavingLunch,
                x.numberHavingAfternoon,
                x.numberEatAfternoonSnack,
                x.numberHaveDinner,
                '',
            ]);
        }


    }
    async  function calculateTotalMoney (sheetName,listExportExcelStaticsBySheetDate,typeSheet){
        let dataList = []
        if (typeSheet === 'date'){
            for (let i = 0; i < listExportExcelStaticsBySheetDate.length; i++) {
                if (sheetName === listExportExcelStaticsBySheetDate[i].nameSheet){
                    dataList.push(listExportExcelStaticsBySheetDate[i])
                }
            }
        }else {
            for (let i = 0; i < listExportExcelStaticsBySheetDate.length; i++) {
                if (sheetName === listExportExcelStaticsBySheetDate[i].className){
                    dataList.push(listExportExcelStaticsBySheetDate[i])
                }
            }
        }
        let sumStudent = 0
        let numberStudy = 0
        let sumAll = ''
        let a1 = 0;
        let a2 = 0;
        let a3 = 0;
        let a4 = 0;
        let a5 = 0;
        let a6 = 0;
        for (let i = 0; i < dataList.length; i++) {
            sumStudent += dataList[i].sumStudent
            numberStudy += dataList[i].numberStudy
            a1 += dataList[i].numberHaveBreakfast;
            a2 += dataList[i].numberEatBreakfastSnacks;
            a3 += dataList[i].numberHavingLunch;
            a4 += dataList[i].numberHavingAfternoon;
            a5 += dataList[i].numberEatAfternoonSnack;
            a6 += dataList[i].numberHaveDinner;
        }
        sumAll = numberStudy + '/' + sumStudent
        return ['Tổng cộng','', sumAll, a1,a2, a3, a4, a5, a6,''];
    }
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');

}


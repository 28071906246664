<template>
  <div>
    <el-dialog
        title="Thống kê thực đơn"
        :visible.sync="showExportExcelStatistics"
        width="30%"
        :close-on-click-modal="false"
        :before-close="handleClose">
      <el-form :model="formRequest" :rules="rules" ref="formRequest">
        <el-row>
          <el-col>
            <el-form-item label="Chọn ngày" prop="listDay">
              <el-date-picker
                  style="margin-left: 23px"
                  format="dd-MM-yyyy"
                  v-model="formRequest.listDay"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="-"
                  start-placeholder="Ngày bắt đầu"
                  end-placeholder="Ngày kết thúc">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="Phạm vi">
              <template>
                <el-radio-group v-model="formRequest.typeClass" @change="handleChangeOneOrManyClass">
                  <el-radio label="one" style="margin-left: 45px">Từng lớp</el-radio>
                  <el-radio label="all">Toàn trường</el-radio>
                </el-radio-group>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="formRequest.typeClass === 'one'">
          <el-col>
            <el-form-item label="Chọn lớp" prop="listIdClass">
              <el-select
                  v-model="formRequest.listIdClass"
                  multiple
                  collapse-tags
                  clearable
                  style="margin-left: 35px; width: 260px"
                  placeholder="Chọn lớp">
                <el-option
                    v-for="item in listClass"
                    :key="item.id"
                    :label="item.className"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="Loại xuất">
              <template>
                <el-radio-group v-model="formRequest.typeSheet">
                  <el-radio label="class" style="margin-left: 45px">Theo lớp</el-radio>
                  <el-radio label="date">Theo ngày</el-radio>
                </el-radio-group>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" :loading="loadingExport">Xuất File</el-button>
        <el-button @click="handleClose">Cancel</el-button>
       </span>
    </el-dialog>
  </div>
</template>
<script>
import classMenuService from "@/services/ClassMenuService";
import {exportToExcelStatistics} from "@/commonFuncion/ExportExcelStatistics";
import moment from "moment/moment";

export default {
  name: "ExportExcelStatisticsDialog",
  props: {
    showExportExcelStatistics: {
      type: Boolean,
      default: () => false
    },
    listClass: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      today: new Date(),
      loadingExport: false,
      formRequest: {
        typeClass: 'one',
        typeSheet: 'class',
        listDay: [],
        listIdClass: [],
      },
      exportToExcelData: {
        titleFile: 'THỐNG KÊ THỰC ĐƠN',
        columnWidths: [5, 30, 20, 20, 20, 20, 20, 20, 20, 50], // Chiều rộng của từng cột
        nameFile: 'Thong_ke_thuc_don_' + moment().format("DD-MM-YYYY_hh-mm-ss"),
      },
      exportExcelStatisticsRequest: {
        typeSheet: "",
        startDate: "",
        endDate: "",
        listIdClass: []
      },
      rules: {
        listDay: [{
          required: true,
          message: "Thời gian không được để trống!",
          trigger: "blur",
        }],
        listIdClass: [{
          required: true,
          message: "Chọn lớp không được để trống!",
          trigger: "blur",
        }],
      },
    }
  },

  watch: {
    'formRequest.listDay'() {
      const differenceInTime = this.formRequest.listDay[1] - this.formRequest.listDay[0];
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      const totalDays = differenceInDays + 1;

      if (this.formRequest.listDay[1] > this.today) {
        this.$message({
          message: "Không được chọn ngày lớn hơn ngày hiện tại!",
          type: "error",
          duration: 5000
        });
        this.formRequest.listDay = [];
      } else if (totalDays > 10) {
        this.$message({
          message: "Không được chọn quá 10 ngày",
          type: "error",
          duration: 5000
        });
        this.formRequest.listDay = [];
      }
    }
  },

  computed: {
    completeExportToExcelData() {
      return {
        ...this.exportToExcelData,
        companyInfo: [],
      }
    }
  },

  methods: {

    handleClose() {
      this.formRequest.listDay = []
      this.formRequest.listIdClass = []
      this.formRequest.typeClass = 'one'
      this.formRequest.typeSheet = 'class'
      this.$emit("close");
    },

    handleChangeOneOrManyClass() {
      this.formRequest.listIdClass = []
      if (this.formRequest.typeClass === 'all') {
        for (let i = 0; i < this.$props.listClass.length; i++) {
          this.formRequest.listIdClass.push(this.$props.listClass[i].id)
        }
      }
      console.log("check 123: ",this.formRequest.listIdClass )
    },
    exportToExcelStatistics,

    async submitForm() {
      this.$refs['formRequest'].validate(async (valid) => {
        if (valid) {
          this.loadingExport = true
          this.exportExcelStatisticsRequest.typeSheet = this.formRequest.typeSheet
          this.exportExcelStatisticsRequest.listIdClass = this.formRequest.listIdClass
          this.exportExcelStatisticsRequest.startDate = moment(this.formRequest.listDay[0]).format('YYYY-MM-DD')
          this.exportExcelStatisticsRequest.endDate = moment(this.formRequest.listDay[1]).format('YYYY-MM-DD')
          await classMenuService.getExportExcelStatistics(this.exportExcelStatisticsRequest).then((response) => {
            this.sheetDate = response.data.data.listSheet
            this.listExportExcelStaticsBySheetDate = response.data.data.listInfoExcelStatistics
            this.typeSheet = response.data.data.typeSheet
          }).catch((error)=>{
            console.log(error)
          })
          // const columnBottoms = this.calculateTotalMoney();
          this.$confirm(
              " Bạn có chắc chắn muốn xuất file hiện tại?",
              "Thông báo!",
              {
                confirmButtonText: "Có",
                cancelButtonText: "Không",
              }
          ).then(async () => {
            if (this.listExportExcelStaticsBySheetDate.length > 0){
              await this.exportToExcelStatistics(this.completeExportToExcelData, this.sheetDate, this.listExportExcelStaticsBySheetDate, this.typeSheet).then(() =>{
                this.$message({
                  message: "Xuất file thành công!",
                  type: "success",
                  duration: 5000
                });
              })
            }else {
              this.$message({
                message: "Không có dữ liệu!",
                type: "error",
                duration: 5000
              });
            }
            this.loadingExport = false
          }).finally(() => this.loadingExport = false);
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
